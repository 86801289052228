import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Home" />
      <h1>Zivilingenieurbüro Brunner in Garsten/Steyr</h1>
      <h2>Arbeitsschwerpunkte</h2>
      <ul>
        <li>Computergestütze Verfahren im Bauingernieurwesen</li>
        <li>Sanierungsplanungen im Siedlungswasserbau</li>
        <li>Neuplanungen im Siedlungswasserbau</li>
      </ul>
      <h2>Weitere Informationen in Kürze</h2>
  </Layout>
)

export default NotFoundPage
